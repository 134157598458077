// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  apiUrl:"http://localhost/giovanierp/backend/web/api/",
  // apiUrl: 'http://52.22.101.184:8081/api/',
    // nodeUrl: 'http://localhost:5001/api/v1/reports/',

   imageUrl: "http://52.22.101.184:8080/api/",
  //  apiUrl: 'http://52.22.101.184:8081/api/',
 // nodeUrl: 'https://giovaniapp.ajatus.co.in/api/v1/reports/',
   nodeUrl: 'https:///apiwebcust.giovaniservice.com/api/v1/reports/',
  //  nodeUrl: 'https://webcust.giovaniservice.com/api/v1/reports/',

  // apiUrl:'https://tempgfs.ajatus.in/api/',
  // url:'https://tempgfs.ajatus.in/',
  // apiUrl:"https://devgfs.ajatus.in/api/"
  apiUrl:'https://control.giovaniservice.com/api/',
  url:'https://control.giovaniservice.com/',
  imageurl:'https://control.giovaniservice.com/uploads/fabrics/',



};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
